import { useOktaAuth } from '@okta/okta-react';
import { Page } from '@optools/legacy-ui/components/Page';
import { useAccessCheck } from '@optools/legacy-ui/hooks/useAccessCheck';
import ToolTile from '@optools/ui/tool-tile';
import {
  CirclePlay,
  ClipboardCheck,
  FileVideo2,
  Film,
  Logs,
  RedoDot,
  Replace,
  Route as RouteIcon,
  SatelliteDish,
  Tv,
} from 'lucide-react';
import { Route, Routes, useLocation } from 'react-router-dom';

function Guest() {
  return (
    <>
      <h3 className="mb-2 text-4xl font-bold">Welcome to the Streaming Operator Platform</h3>
      <p className="text-sm font-normal">
        In order to use the application, you will need to log in via Okta.
      </p>
    </>
  );
}

function Tiles() {
  const { search } = useLocation();
  const {
    showAudit,
    showBtcTools,
    showEsni,
    showIpOverride,
    showLogger,
    showMediaValidation,
    showMilestones,
    showOverrides,
    showPartnerStreamDelivery,
    showRed5,
  } = useAccessCheck();
  return (
    <div className="grid gap-4 sm:grid-cols-[repeat(auto-fill,minmax(180px,1fr))]">
      {showBtcTools && (
        <ToolTile icon={SatelliteDish} title="BTC Tools" to="/secure/btc-tools/schedule" />
      )}
      {showOverrides && (
        <ToolTile icon={RedoDot} title="Overrides" to="/secure/overrides/experience" />
      )}
      {showIpOverride && (
        <ToolTile icon={Replace} title="Blackout Override" to="/secure/ip-override" />
      )}
      {showLogger && <ToolTile icon={Tv} title="Logger" to="/secure/logger" />}
      {showMediaValidation && (
        <ToolTile icon={ClipboardCheck} title="Media Validation" to="/secure/media-validation" />
      )}
      {showMilestones && <ToolTile icon={Film} title="Milestones" to="/secure/milestones" />}
      {showAudit && <ToolTile icon={Logs} title="Audit & Logging" to="/secure/audit" />}
      {showRed5 && <ToolTile icon={CirclePlay} title="Red5 POC" to={`/secure/red5${search}`} />}
      {showPartnerStreamDelivery && (
        <ToolTile icon={RouteIcon} title="Partner Delivery" to={`/secure/partner/mapping`} />
      )}
      {showEsni && <ToolTile icon={FileVideo2} title="ESNI Management" to="/secure/esni" />}
    </div>
  );
}

export default function Home() {
  const { authState } = useOktaAuth();
  const isAuthenticated = !!authState?.isAuthenticated;

  const element = isAuthenticated ? <Tiles /> : <Guest />;

  return (
    <Routes>
      <Route element={<Page title="Home" />}>
        <Route element={element} index />
      </Route>
    </Routes>
  );
}
