import { useAccessCheck } from '@optools/legacy-ui/hooks/useAccessCheck';
import { Button } from '@optools/ui/button';
import { cn } from '@optools/ui/utils';
import {
  CirclePlay,
  ClipboardCheck,
  FileVideo2,
  Film,
  House,
  Logs,
  PanelLeftClose,
  PanelLeftOpen,
  RedoDot,
  Replace,
  Route,
  SatelliteDish,
  Tv,
} from 'lucide-react';
import { useLocation } from 'react-router-dom';

import { AccountMenu } from './account-menu';
import { SettingsMenu } from './settings-menu';
import { SidebarLink } from './sidebar-link';

export interface SidebarProps {
  onToggleOpen: () => void;
  open: boolean;
}

export function Sidebar({ onToggleOpen, open }: SidebarProps) {
  const { search } = useLocation();

  const {
    showAudit,
    showBtcTools,
    showEsni,
    showIpOverride,
    showLogger,
    showMediaValidation,
    showMilestones,
    showOverrides,
    showPartnerStreamDelivery,
    showRed5,
  } = useAccessCheck();

  return (
    <aside
      className={cn(
        'inset-y ease left-0 z-20 flex h-full flex-col overflow-y-auto overflow-x-hidden border-r transition-[width] duration-300',
        open ? 'w-64' : 'w-14',
      )}>
      <div className="relative flex items-center justify-end border-b p-2">
        <h1
          className={cn(
            'ease absolute left-5 whitespace-nowrap text-xs font-bold transition-opacity duration-300',
            open ? 'opacity-100 delay-100' : 'opacity-0',
          )}>
          Streaming Operator Platform
        </h1>
        <Button
          aria-label={`${open ? 'Close' : 'Open'} sidebar`}
          className="relative z-10"
          size="icon"
          title={`${open ? 'Close' : 'Open'} sidebar`}
          variant="outline"
          onClick={onToggleOpen}>
          {open ? <PanelLeftClose className="size-5" /> : <PanelLeftOpen className="size-5" />}
        </Button>
      </div>
      <nav className="flex flex-col gap-2 overflow-x-hidden p-2">
        <SidebarLink icon={House} name="Home" open={open} to="/" />

        {showBtcTools && (
          <SidebarLink
            icon={SatelliteDish}
            name="BTC Tools"
            open={open}
            to="/secure/btc-tools/schedule"
          />
        )}
        {showOverrides && (
          <SidebarLink
            icon={RedoDot}
            name="Overrides"
            open={open}
            to="/secure/overrides/experience"
          />
        )}
        {showIpOverride && (
          <SidebarLink
            icon={Replace}
            name="Blackout Override"
            open={open}
            to="/secure/ip-override"
          />
        )}
        {showLogger && <SidebarLink icon={Tv} name="Logger" open={open} to="/secure/logger" />}
        {showMediaValidation && (
          <SidebarLink
            icon={ClipboardCheck}
            name="Media Validation"
            open={open}
            to="/secure/media-validation"
          />
        )}
        {showMilestones && (
          <SidebarLink icon={Film} name="Milestones" open={open} to="/secure/milestones" />
        )}
        {showAudit && (
          <SidebarLink icon={Logs} name="Audit & Logging" open={open} to="/secure/audit" />
        )}
        {showRed5 && (
          <SidebarLink icon={CirclePlay} name="Red5 POC" open={open} to={`/secure/red5${search}`} />
        )}
        {showPartnerStreamDelivery && (
          <SidebarLink icon={Route} name="Partner Delivery" open={open} to="/secure/partner" />
        )}
        {showEsni && (
          <SidebarLink icon={FileVideo2} name="ESNI Management" open={open} to="/secure/esni" />
        )}
      </nav>

      <nav className="mt-auto grid gap-1 p-2">
        <SettingsMenu open={open} />
        <AccountMenu open={open} />
      </nav>
    </aside>
  );
}
